<template>
    <v-app>
        <v-card>
            <v-card-title>
                <span>Inventario</span>
                <v-spacer></v-spacer>
                <v-btn class="" @click="fetchData"  text icon color="blue">
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                <v-text-field
                v-model="search"
                append-icon="search"
                label="Búsqueda"
                single-line
                hide-details
                ></v-text-field>
            </v-card-title>
            <v-card-text>
                <div class="datepicker-trigger">
                    <v-text-field
                        
                        id="datepicker-trigger"
                        placeholder="Filtrar por rango de fechas"
                        :value="formatDates(dateOne, dateTwo)"
                    />
                    <AirbnbStyleDatepicker
                        :trigger-element-id="'datepicker-trigger'"
                        :mode="'range'"
                        :fullscreen-mobile="true"
                        :date-one="dateOne"
                        :date-two="dateTwo"
                        @date-one-selected="val => { dateOne = val }"
                        @date-two-selected="val => { dateTwo = val }"
                    />
                </div>
                <v-data-table
                    :headers="headers"
                    :items="tableData"
                    :search="search"
                    v-model="selected"
                    no-results-text="No hay resultados disponibles"
                    no-data-text="No hay datos disponibles"
                    loading-text="Cargando datos..."
                    :sort-by="'id'"
                    :sort-desc="true"
                    class="elevation-1 datatable"
                    
                >
                    <template v-slot:item.name="{ item }">
                        <td class="text-center">
                            <a :href="'#/productos/envasados/'+item.generic_sku">{{item.name}}</a>
                        </td>
                    </template>
                    <template v-slot:item.generic_sku="{ item }">
                        <td class="text-center">
                            <a :href="'#/productos/envasados/'+item.generic_sku">{{item.generic_sku}}</a>
                        </td>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import format from 'date-fns/format';
export default {
    data()
    {
        return {
            loading: false,
            dateFormat: 'D MMM',
            dateOne: '',
            dateTwo: '',
            loadingAssign: false,
            range: {},
            headers: [
                {text: 'Producto', value: 'name'},//
                {text: 'SKU Genérico', value: 'generic_sku'},//
                {text: 'Cantidad Envasados', value: 'envased_quantity'},
                {text: 'Cantidad Total', value: 'quantity'},//
                {text: 'Formato', value: 'format'},//
            ],
            tableData: [],
            search: '',
            selected: [],
            itemPivot: undefined,
            dialogComentario: false,
            motivoMerma: '',
        }
    },
    created(){
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Inventario ", route: "/productos/envasados" },
        ]);
    },
    mounted()
    {
        this.fetchData();
    },
    methods: {
        fetchData()
        {
            this.axios({
                method: 'POST',
                url: 'inventory/consolided_packaged_products/ns',
                data: {
                    store: this.$store.state.passport.user.default_store_id
                }
            }).then( response => {
                let data = [];
                Object.keys(response.data).forEach(key => {
                    let cantidad = 0.0;
                    let envased_quantity = 0;
                    response.data[key].forEach(elem => {
                        cantidad+= parseFloat(elem.quantity);
                        envased_quantity++;
                    });
                    data.push({
                        generic_sku: key,
                        name: response.data[key][0].name,
                        quantity: cantidad.toFixed(2)+' '+response.data[key][0].unit,
                        unit: response.data[key][0].unit,
                        format: response.data[key][0].format,
                        envased_quantity
                    });
                });
                this.tableData = data;
            }).catch( error => {
                console.log(error);
            });
        },
        filtroRangoFechas(value, search, item){
            console.log('item', item);
            console.log('value', value);
            console.log('search',search);
            if( !this.dateOne || !this.dateTwo ){
                return true;
            }else{
                if( this.$moment(item.created_at).isSameOrAfter(this.$moment(this.dateOne)) && this.$moment(item.created_at).isSameOrBefore(this.$moment(this.dateTwo)) ){
                    return true;
                }else{
                    return false;
                }
            }
        },
        formatDates(dateOne, dateTwo) {
            let formattedDates = ''
            if (dateOne) {
                formattedDates = format(dateOne, this.dateFormat)
            }
            if (dateTwo) {
                formattedDates += ' - ' + format(dateTwo, this.dateFormat)
            }
            return formattedDates
        },
        
    },
}
</script>
